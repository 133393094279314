import React from "react";
import GoogleMap from "google-map-react";
import pinImg from "../../assets/images/pin.png";
import Marker from "./Marker";

const Map = ({ type, classes }) => {
  const defaultProps = {
      center: {
          lat: 6.5128793,
          lng: 3.348072
      },
     
      zoom: 12
  }
                    
  // const style3 = {
  //   panControl: true,
  //   zoomControl: true,
  //   mapTypeControl: true,
  //   streetViewControl: true,
  //   scrollwheel: false,
  //   styles: [
  //     { elementType: "geometry", stylers: [{ color: "#f5f5f5" }] },
  //     { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
  //     { elementType: "labels.text.fill", stylers: [{ color: "#616161" }] },
  //     { elementType: "labels.text.stroke", stylers: [{ color: "#f5f5f5" }] },
  //     {
  //       featureType: "administrative.land_parcel",
  //       elementType: "labels.text.fill",
  //       stylers: [{ color: "#bdbdbd" }],
  //     },
  //     {
  //       featureType: "poi",
  //       elementType: "geometry",
  //       stylers: [{ color: "#eeeeee" }],
  //     },
  //     {
  //       featureType: "poi",
  //       elementType: "labels.text.fill",
  //       stylers: [{ color: "#757575" }],
  //     },
  //     {
  //       featureType: "poi.park",
  //       elementType: "geometry",
  //       stylers: [{ color: "#e5e5e5" }],
  //     },
  //     {
  //       featureType: "poi.park",
  //       elementType: "labels.text.fill",
  //       stylers: [{ color: "#9e9e9e" }],
  //     },
  //     {
  //       featureType: "road",
  //       elementType: "geometry",
  //       stylers: [{ color: "#ffffff" }],
  //     },
  //     {
  //       featureType: "road.arterial",
  //       elementType: "labels.text.fill",
  //       stylers: [{ color: "#757575" }],
  //     },
  //     {
  //       featureType: "road.highway",
  //       elementType: "geometry",
  //       stylers: [{ color: "#dadada" }],
  //     },
  //     {
  //       featureType: "road.highway",
  //       elementType: "labels.text.fill",
  //       stylers: [{ color: "#616161" }],
  //     },
  //     {
  //       featureType: "road.local",
  //       elementType: "labels.text.fill",
  //       stylers: [{ color: "#9e9e9e" }],
  //     },
  //     {
  //       featureType: "transit.line",
  //       elementType: "geometry",
  //       stylers: [{ color: "#e5e5e5" }],
  //     },
  //     {
  //       featureType: "transit.station",
  //       elementType: "geometry",
  //       stylers: [{ color: "#eeeeee" }],
  //     },
  //     {
  //       featureType: "water",
  //       elementType: "geometry",
  //       stylers: [{ color: "#c9c9c9" }],
  //     },
  //     {
  //       featureType: "water",
  //       elementType: "labels.text.fill",
  //       stylers: [{ color: "#9e9e9e" }],
  //     },
  //   ],
  // };
  // const style2 = {
  //   panControl: true,
  //   zoomControl: true,
  //   mapTypeControl: true,
  //   streetViewControl: true,
  //   scrollwheel: false,
  //   styles: [
  //     { elementType: "geometry", stylers: [{ color: "#212121" }] },
  //     { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
  //     { elementType: "labels.text.fill", stylers: [{ color: "#757575" }] },
  //     { elementType: "labels.text.stroke", stylers: [{ color: "#212121" }] },
  //     {
  //       featureType: "administrative",
  //       elementType: "geometry",
  //       stylers: [{ color: "#757575" }],
  //     },
  //     {
  //       featureType: "administrative.country",
  //       elementType: "labels.text.fill",
  //       stylers: [{ color: "#9e9e9e" }],
  //     },
  //     {
  //       featureType: "administrative.land_parcel",
  //       stylers: [{ visibility: "off" }],
  //     },
  //     {
  //       featureType: "administrative.locality",
  //       elementType: "labels.text.fill",
  //       stylers: [{ color: "#bdbdbd" }],
  //     },
  //     {
  //       featureType: "poi",
  //       elementType: "labels.text.fill",
  //       stylers: [{ color: "#757575" }],
  //     },
  //     {
  //       featureType: "poi.park",
  //       elementType: "geometry",
  //       stylers: [{ color: "#181818" }],
  //     },
  //     {
  //       featureType: "poi.park",
  //       elementType: "labels.text.fill",
  //       stylers: [{ color: "#616161" }],
  //     },
  //     {
  //       featureType: "poi.park",
  //       elementType: "labels.text.stroke",
  //       stylers: [{ color: "#1b1b1b" }],
  //     },
  //     {
  //       featureType: "road",
  //       elementType: "geometry.fill",
  //       stylers: [{ color: "#2c2c2c" }],
  //     },
  //     {
  //       featureType: "road",
  //       elementType: "labels.text.fill",
  //       stylers: [{ color: "#8a8a8a" }],
  //     },
  //     {
  //       featureType: "road.arterial",
  //       elementType: "geometry",
  //       stylers: [{ color: "#373737" }],
  //     },
  //     {
  //       featureType: "road.highway",
  //       elementType: "geometry",
  //       stylers: [{ color: "#3c3c3c" }],
  //     },
  //     {
  //       featureType: "road.highway.controlled_access",
  //       elementType: "geometry",
  //       stylers: [{ color: "#4e4e4e" }],
  //     },
  //     {
  //       featureType: "road.local",
  //       elementType: "labels.text.fill",
  //       stylers: [{ color: "#616161" }],
  //     },
  //     {
  //       featureType: "transit",
  //       elementType: "labels.text.fill",
  //       stylers: [{ color: "#757575" }],
  //     },
  //     {
  //       featureType: "water",
  //       elementType: "geometry",
  //       stylers: [{ color: "#000000" }],
  //     },
  //     {
  //       featureType: "water",
  //       elementType: "labels.text.fill",
  //       stylers: [{ color: "#3d3d3d" }],
  //     },
  //   ],
  // };
  return (
      <div
          id={`map-style-${type === "3" ? "3" : "2"}`}
          className={classes || ""}
      >
          <GoogleMap
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
              bootstrapURLKeys={{
                  key: "AIzaSyA_O-vvaa6KAqb6GYHzh4A7BLFr-FHE6l8"
              }}
              // options={type === "3" ? style3 : style2}
          >
              <Marker
                  icon={pinImg}
                  position={{ lat: -73.995763, lng: 40.716304 }}
                  text= "Lampnet"
              />
          </GoogleMap>
      </div>
  )



};

export default Map;
