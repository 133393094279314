import React from 'react'
import img1 from "../../assets/images/bg-right-image.jpg";
import ReactWOW from 'react-wow';
const AboutMeSix = () => {
    const [toggler, setToggler] = React.useState(false);

    return (
        <div>
          <ReactWOW animation="fadeInLeft" duration="2">
              <section className="section darken-blue">
                <div className="bg-wrap">
                  <div className="col-md-6 col-sm-4 bg-flex bg-flex-left">
                    <div
                      className="bg-flex-holder bg-flex-cover"
                      style={{ backgroundImage: `url(${img1})` }}
                    ></div>
                  </div>
                </div>
                <div className="container">
                  <div className="col-md-5 col-sm-7 col-md-offset-7 col-sm-offset-5">
                    <h1 className="mb-0 play-font white-color">About Us</h1>
                    <h3 className="mt-10 kaushan-font pink-color">
                      
                    </h3>
                    <div className="mt-30">
                      <p className="">
                      LampNet Solutions is an agile software development and
                      consulting company. We bring our expertise and competences to
                      bear in the implementation and customization of integrated Cloud
                      Solutions, ERP, CRM and other business software optimized to
                      exceed customers’ expectations. We offer a suite of secure &
                      compliant solutions that meets and exceeds requirements for
                      small to medium-sized businesses, also offering transparent
                      solutions.
                      Our activities are geared around three business support pillars
                      namely:
                      Digital Transformation, Operational Excellence and Protection
                      Assurance.
                

                      </p>
                      <p className="mt-30">
                         LampNet Solutions is a private company registered in the
                        Federal republic of Nigeria under the Companies and Allied
                        Matter Act 1990. It was setup to provide business solutions to
                        small, medium and large sized organization. As an independent
                        software vendor and partners with the world leading Technology
                        Vendors, we major in the delivery of timely and innovative IT
                        solutions and provide IT trainings for corporate organizations,
                        institutions, members of
                        professional bodies and others.
                        LampNet Solutions is one of the few IT solution providers in
                        Africa with a trademark of providing world-class solutions in
                        Africa for Africans. We pride in providing world class solutions to
                        every African business in Africa.
                      </p>
                      <div className="inline-btn mt-30">
                        <a
                          href="https://www.youtube.com/watch?v=sU3FkzUKHXU"
                          className="btn btn-sm btn-circle btn-outline-white ml-0 popup-youtube"
                          onClick={(e) => {
                            e.preventDefault();
                            setToggler(!toggler);
                          }}
                        >
                          <i className="eicon ion-android-arrow-dropright"></i>
                        </a>
                        <p>Watch Our Video</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>  
            </ReactWOW>
        </div>
    )
}

export default AboutMeSix
