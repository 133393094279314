import { contactApi } from "../../api/api"

export const contactFun = async (
    URL,
    formData,
    setIsProcessing,
    setFormMsg,
    setShowMsg,
    notify,
    resetForm
) => {
    try {
        setIsProcessing(true)
        const {
            data: { message }
        } = await contactApi("/contact-messages", formData)
        setIsProcessing(false)
        notify(message)
        resetForm()
    } catch (error) {
        if (error.response) {
            setIsProcessing(false)
            setShowMsg(true)
            setFormMsg(error.response.data.error)
        }
    }
}
