import React from "react";
import { Link } from 'react-router-dom'
import ReactWow from "react-wow";

const WelcomeOne = ({ image, toggler, setToggler }) => (
    <section className="pattern-bg-light1 red-bg">
        <div className="col-md-6 col-sm-4 bg-flex bg-flex-right">
            <div
                className="bg-flex-holder bg-flex-cover"
                style={{ backgroundImage: `url(${image})` }}
            ></div>
        </div>
        <div className="container">
            <div className="col-md-5 col-sm-7 white-color">
                <ReactWow animation="fadeInUp" delay="0.1s">
                    <h4 className="text-uppercase mb-0">About Lampnets</h4>
                </ReactWow>
                <ReactWow animation="fadeInUp" delay="0.2s">
                    <div>
                        <h2 className="mt-10">Who We Are</h2>
                        <hr className="white-bg left-line" />
                    </div>
                </ReactWow>
                <ReactWow animation="fadeInUp" delay="0.3s">
                    <div className="mt-30">
                        <p>
                            Lampnet Solutions is a customer-centric cutting-edge
                            technology service provider of World-Class Solutions
                            for high business growth and transformation with
                            experience in Mobile Application development,
                            Security and Cloud based technologies. LampNet
                            Solutions is an agile software development and
                            consulting company. We bring our expertise and
                            competences to bear in the implementation and
                            customization of integrated Cloud Solutions, ERP,
                            CRM and other business software optimized to exceed
                            customers’ expectations. We offer a suite of secure
                            & compliant solutions that meets and exceeds
                            requirements for small to medium-sized businesses,
                            also offering transparent solutions. Our activities
                            are geared around three business support pillars
                            namely: Digital Transformation, Operational
                            Excellence and Protection Assurance.
                        </p>
                      
                        <p className="mt-20">
                            <Link
                                to="/about-us"
                                className="btn btn-outline-white font-500 btn-md btn-square remove-margin"
                            >
                                Read More
                            </Link>
                        </p>
                    </div>
                </ReactWow>
            </div>
        </div>
    </section>
)

export default WelcomeOne;
