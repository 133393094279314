import React from "react"

const AttrNavSocial = () => (
    <div className="attr-nav">
        <ul className="social-media-dark social-top">
            <li>
                <a
                    href="https://web.facebook.com/LampnetS"
                    className="ion-social-facebook"
                >
                    <span></span>
                </a>
            </li>
            <li>
                <a
                    href="https://www.linkedin.com/company/lampnet-solutions/mycompany/?viewAsMember=true"
                    className="ion-social-linkedin"
                >
                    <span></span>
                </a>
            </li>
            {/* <li>
                <a
                    href="https://www.instagram.com/lampnet_solutions/"
                    className="ion-social-instagram"
                >
                    <span></span>
                </a>
            </li> */}
            <div id="google_translate_element"></div>
        </ul>
    </div>
)

export default AttrNavSocial
