import React from "react";
// DATA Files
import dataNavbar from "../../data/Navbar/nav-fitness-data.json";
// Images
import imgBg from "../../assets/images/slides/title-bg-22.jpg";
// Components
import PageTitleTwo from "../../components/PageTitle/PageTitleTwo";
import MapSection from "../../components/Map/MapSection";
import ContactSix from "../../components/Contact/ContactSix";
import ContactInfoOne from "../../components/Contact/ContactInfoOne";
import NavbarSimpleSocial from "../../components/Navbar/NavbarSimpleSocial";
import FooterTwo from "../../components/Footer/FooterTwo";

const ContactPage = () => (
    <>
        <NavbarSimpleSocial data={dataNavbar} type="white" />
        <PageTitleTwo
            title="Contact Us"
            tagline="Feel free to call us 24 hours"
            image={imgBg}
        />
        <ContactInfoOne />
        <MapSection />
        <ContactSix title="Contact Us" />
        <FooterTwo />
    </>
)

export default ContactPage;
