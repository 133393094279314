import React, { useState } from "react"
import { application } from "./ApplicationFun"
import * as validator from "./../../helpers/validator"
import RequirementOne from "./RequirementOne"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const Form = () => {
    const [surname, setSurname] = useState("")
    const [firstname, setFirstName] = useState("")
    const [middlename, setMiddleName] = useState("")
    const [sex, setSex] = useState("")
    const [dob, setDob] = useState("")
    const [email, setEmail] = useState("")
    const [position, setPosition] = useState("")
    const [course, setCourse] = useState("")
    const [qualification, setQualification] = useState("")
    const [experience, setExperience] = useState("")
    const [phone, setPhone] = useState("")
    const [address, setAddress] = useState("")
    const [city, setCity] = useState("")
    const [country, setCountry] = useState("")
    const [nationality, setNationality] = useState("")
    const [nyscStatus, setNyscStatus] = useState("")
    const [letter, setLetter] = useState("")
    const [isProcessing, setIsProcessing] = useState(false)
  
    const [showErr, setShowErr] = useState(false)

    //error state

    const [surnameErr, setSurnameErr] = useState("")
    const [firstnameErr, setFirstNameErr] = useState("")
    const [middleNameErr, setMiddleNameErr] = useState("")
    const [sexErr, setSexErr] = useState("")
    const [dobErr, setDobErr] = useState("")
    const [emailErr, setEmailErr] = useState("")
    const [courseErr, setCourseErr] = useState("")
    const [positionErr, setPositionErr] = useState("")
    const [qualificationErr, setQualificationErr] = useState("")
    const [experienceErr, setExperienceErr] = useState("")
    const [phoneErr, setPhoneErr] = useState("")
    const [addressErr, setAddressErr] = useState("")
    const [cityErr, setCityErr] = useState("")
    const [countryErr, setCountryErr] = useState("")
    const [nationalityErr, setNationalityErr] = useState("")
    const [nyscStatusErr, setNyscStatusErr] = useState("")
    const [letterErr, setLetterErr] = useState("")
    const [message, setMessage] = useState("")

    const handleChangeSname = e => {
        setSurname(e.target.value)
        setSurnameErr("")
    }

    const handleChangeFname = e => {
        setFirstName(e.target.value)
        setFirstNameErr("")
    }

    const handleChangeMname = e => {
        setMiddleName(e.target.value)
        setMiddleNameErr("")
    }

    const handleChangeSex = e => {
        setSex(e.target.value)
        setSexErr("")
    }

    const handleChangeDob = e => {
        setDob(e.target.value)
        setDobErr("")
    }

    const handleChangeEmail = e => {
        setEmail(e.target.value)
        setEmailErr("")
    }

    const handleChangePosition = e => {
        setPosition(e.target.value)
        setPositionErr("")
    }

    const handleChangeCourse = e => {
        setCourse(e.target.value)
        setCourseErr("")
    }

    const handleChangeQualification = e => {
        setQualification(e.target.value)
        setQualificationErr("")
    }

    const handleChangeExperience = e => {
        setExperience(e.target.value)
        setExperienceErr("")
    }

    const handleChangePhone = e => {
        setPhone(e.target.value)
        setPhoneErr("")
    }
    const handleChangeAddress = e => {
        setAddress(e.target.value)
        setAddressErr("")
    }

    const handleChangeCity = e => {
        setCity(e.target.value)
        setCityErr("")
    }

    const handleChangeCountry = e => {
        setCountry(e.target.value)
        setCountryErr("")
    }

    const handleChangeNationality = e => {
        setNationality(e.target.value)
        setNationalityErr("")
    }

    const handleChangeNyscStatus = e => {
        setNyscStatus(e.target.value)
        setNyscStatusErr("")
    }

    const handleChangeLetter = e => {
        setLetter(e.target.value)
        setLetterErr("")
    }
    const notify = msg => toast.success(msg)
    const resetForm = () => {
    
     setSurname("")
     setFirstName("") 
     setMiddleName("")
     setSex("")
     setDob("")
     setEmail("")
     setCourse("")
     setPosition("")
     setQualification("")
     setExperience("")
     setPhone("")
     setAddress("")
     setCity("")
     setCountry("")
     setNationality("")
     setNyscStatus("")
     setLetter("")
   
       
    }
    
    const handleSubmit = e => {
       
        
        e.preventDefault()
       
        let error = false
        if (validator.nameValidator(surname)) {
            error = true
            setSurnameErr(validator.nameValidator(surname))
        }

        if (validator.nameValidator(firstname)) {
            error = true
            setFirstNameErr(validator.nameValidator(firstname))
        }

        if (validator.nameValidator(middlename)) {
            error = true
            setMiddleNameErr(validator.nameValidator(middlename))
        }

        if (validator.stringValidator(sex)) {
            error = true
            setSexErr(validator.stringValidator(sex))
        }

        if (validator.stringValidator(dob)) {
            error = true
            setDobErr(validator.stringValidator(dob))
        }

        if (validator.emailValidator(email)) {
            error = true
            setEmailErr(validator.emailValidator(email))
        }
        
        if(validator.stringValidator(position)){
            error = true
            setPositionErr(validator.stringValidator(position))
        }
        if (validator.stringValidator(course)) {
            error = true
            setCourseErr(validator.stringValidator(course))
        }

        if (validator.stringValidator(qualification)) {
            error = true
            setQualificationErr(validator.stringValidator(qualification))
        }

        if (validator.stringValidator(experience)) {
            error = true
            setExperienceErr(validator.stringValidator(experience))
        }

        if (validator.phoneValidator(phone)) {
            error = true
            setPhoneErr(validator.phoneValidator(phone))
        }

        if (validator.stringValidator(address)) {
            setAddressErr(validator.stringValidator(address))
        }

        if (validator.stringValidator(city)) {
            error = true
            setCityErr(validator.stringValidator(city))
        }

        if (validator.stringValidator(country)) {
            setCountryErr(validator.stringValidator(country))
        }
        if (validator.stringValidator(nationality)) {
            error = true
            setNationalityErr(validator.stringValidator(nationality))
        }

        if (validator.stringValidator(nyscStatus)) {
            error = true
            setNyscStatusErr(validator.stringValidator(nyscStatus))
        }

        if (validator.stringValidator(letter)) {
            error = true
            setLetterErr(validator.stringValidator(letter))
        }

        if (!error) {
            setIsProcessing(true)
            const formData = {
                surname,
                firstname,
                middlename,
                email,
                position,
                sex,
                dob,
                course,
                qualification,
                experience,
                phone,
                address,
                city,
                country,
                nyscStatus,
                letter
            }
           
            application(URL, formData, setIsProcessing, setMessage, setShowErr, notify, resetForm)
         
        }
    }
    return (
        <>
          <ToastContainer/>
            <RequirementOne />
            <section style={{ paddingTop: "5px" }}>
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <h2
                            className=" m-5 font-300  text-uppercase"
                            style={{ fontSize: "20px" }}
                        >
                            Application Form
                        </h2>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <form>
                                <div className="form-row">
                                    <div className="form-group col-md-4">
                                        <label htmlFor="inputEmail4">
                                            Surname
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="inputEmail4"
                                            placeholder="Your surname"
                                            value={surname}
                                            onChange={handleChangeSname}
                                        />
                                        <span className="text-danger">
                                            {surnameErr}
                                        </span>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="inputPassword4">
                                            First Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="inputPassword4"
                                            placeholder="first name"
                                            value={firstname}
                                            onChange={handleChangeFname}
                                        />
                                        <span className="text-danger">
                                            {firstnameErr}
                                        </span>
                                    </div>

                                    <div className="form-group col-md-4">
                                        <label htmlFor="inputPassword4">
                                            Middle Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="inputPassword4"
                                            placeholder="middle name"
                                            value={middlename}
                                            onChange={handleChangeMname}
                                        />
                                        <span className="text-danger">
                                            {middleNameErr}
                                        </span>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputAddress">
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="inputAddress"
                                            placeholder="Your email address"
                                            value={email}
                                            onChange={handleChangeEmail}
                                        />
                                        <span className="text-danger">
                                            {emailErr}
                                        </span>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="input">Position</label>
                                        <select
                                            id="inputState"
                                            className="form-control"
                                            onChange={handleChangePosition}
                                            value={position}
                                        >
                                            <option value="">Choose...</option>
                                            <option value="ui/ux designer">
                                                UI/UX Designer
                                            </option>
                                            <option value="presales engineer">
                                                Presales Engineer
                                            </option>
                                            <option value="sales intern">
                                                Sales Intern
                                            </option>
                                        </select>
                                        <span className="text-danger">
                                            {positionErr}
                                        </span>
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputState">Sex</label>
                                        <select
                                            id="inputState"
                                            className="form-control"
                                            onChange={handleChangeSex}
                                            value={sex}
                                        >
                                            <option value="">Choose...</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">
                                                Female
                                            </option>
                                        </select>
                                        <span className="text-danger">
                                            {sexErr}
                                        </span>
                                    </div>

                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputState">
                                            Date of Birth{" "}
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={dob}
                                            onChange={handleChangeDob}
                                        />
                                        <span className="text-danger">
                                            {dobErr}
                                        </span>
                                    </div>
                                </div>
                                <div className="form-group col-md-12">
                                    <label htmlFor="course">
                                        Course of Study
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="course"
                                        placeholder="Your course"
                                        onChange={handleChangeCourse}
                                        value={course}
                                    />
                                    <span className="text-danger">
                                        {courseErr}
                                    </span>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputEmail4">
                                            Academic Qualification
                                        </label>
                                        <select
                                            id="inputState"
                                            className="form-control"
                                            value={qualification}
                                            onChange={handleChangeQualification}
                                        >
                                            <option selected>Choose...</option>
                                            <option value="BSc">B.sc</option>
                                            <option value="OND">OND</option>
                                            <option value="ND">ND</option>
                                            <option value="Others">
                                                Others
                                            </option>
                                        </select>
                                        <span className="text-danger">
                                            {qualificationErr}
                                        </span>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="experience">
                                            Year of Experience
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="experience"
                                            placeholder="experience"
                                            value={experience}
                                            onChange={handleChangeExperience}
                                        />
                                        <span className="text-danger">
                                            {experienceErr}
                                        </span>
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-4">
                                        <label htmlFor="city">
                                            Phone Number
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="address"
                                            placeholder="Your phone number"
                                            value={phone}
                                            onChange={handleChangePhone}
                                        />
                                        <span className="text-danger">
                                            {phoneErr}
                                        </span>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="inputEmail4">
                                            Address
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="address"
                                            placeholder="Your address"
                                            value={address}
                                            onChange={handleChangeAddress}
                                        />
                                        <span className="text-danger">
                                            {addressErr}
                                        </span>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="city">City</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="address"
                                            placeholder="Your city"
                                            value={city}
                                            onChange={handleChangeCity}
                                        />
                                        <span className="text-danger">
                                            {cityErr}
                                        </span>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-4">
                                        <label htmlFor="residence">
                                            Country of Residence
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="residence"
                                            value={country}
                                            onChange={handleChangeCountry}
                                        />
                                        <span className="text-danger">
                                            {countryErr}
                                        </span>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="nationality">
                                            Nationality
                                        </label>
                                        <select
                                            id="nationality"
                                            className="form-control"
                                            value={nationality}
                                            onChange={handleChangeNationality}
                                        >
                                            <option selected value="">
                                                Choose...
                                            </option>
                                            <option value="Nigerian">
                                                Nigerian
                                            </option>
                                            <option value="">Other</option>
                                        </select>
                                        <span className="text-danger">
                                            {nationalityErr}
                                        </span>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="inputZip">
                                            Current Nysc Status
                                        </label>
                                        <select
                                            id="nationality"
                                            className="form-control"
                                            value={nyscStatus}
                                            onChange={handleChangeNyscStatus}
                                        >
                                            <option selected value="">
                                                Choose...
                                            </option>
                                            <option value="Completed">
                                                Completed
                                            </option>
                                            <option value="Not-Completed">
                                                Not Completed
                                            </option>
                                        </select>
                                        <span className="text-danger">
                                            {nyscStatusErr}
                                        </span>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group">
                                        <label id="letter">Cover Letter</label>
                                        <textarea
                                            className="form-control"
                                            id="letter"
                                            value={letter}
                                            onChange={handleChangeLetter}
                                        ></textarea>
                                        <span className="text-danger">
                                            {letterErr}
                                        </span>
                                    </div>
                                </div>

                                <div className="text-center">
                                    {isProcessing ? (
                                        <button
                                            type="submit"
                                            className="btn btn-outline-color btn-md  btn-animate remove-margin"
                                            disabled
                                        >
                                            Processing...
                                        </button>
                                    ) : (
                                        <button
                                            type="submit"
                                            className="btn btn-outline-color btn-md  btn-animate remove-margin"
                                            onClick={handleSubmit}
                                        >
                                            Send
                                        </button>
                                    )}
                                </div>
                            </form>
                          
                            {showErr ? (
                                <div className="mt-4">
                                    <div className="alert alert-danger">
                                        <p>{message}</p>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Form
