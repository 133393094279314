import React from "react"
import logoBlack from "../../assets/images/logo.png"
import { Link } from "react-router-dom"

const FooterTwo = () => (
    <>
        <footer className="footer dark-block" id="footer-fixed">
            <div className="footer-main">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 col-md-4">
                            <div className="widget widget-text">
                                <div className="logo logo-footer">
                                    <Link to="/">
                                        <img
                                            className="logo logo-display"
                                            src={logoBlack}
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="widget-links">
                                    <ul>
                                        <li>
                                            {" "}
                                            <i className="icon-map"></i>128
                                            Itire Rd, Lawanson Surulere Lagos,
                                            Nigeria
                                        </li>
                                        <li>
                                            <i className="icon-phone"></i>
                                            +234 816 663 7303
                                        </li>
                                        <li>
                                            <i className="icon-mail"></i>
                                            support@lampnets.com
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-2">
                            <div className="widget widget-links">
                                <h5 className="widget-title">Links</h5>
                                <ul>
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li>
                                        <Link to="/about-us">About Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/our-services">
                                            Our Services
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/blog">Blog</Link>
                                    </li>
                                    <li>
                                        <Link to="/partnership">
                                            Partnership Programmes
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-2">
                            <div className="widget widget-links">
                                <h5 className="widget-title">Working Days</h5>
                                <ul>
                                    <li>Monday - Friday 8:00am-16:00pm</li>
                                    <li>Saturday 10:00am - 14:00pm</li>
                                    <li>Sunday 9:00am - 12:00pm</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <div className="widget widget-text">
                                <h5 className="widget-title">Popular Tags</h5>
                                <div className="popular-tags">
                                    <Link to="/">Minimal</Link>
                                    <Link to="/">One Page</Link>
                                    <Link to="/">Design</Link>
                                    <Link to="/">Creative</Link>
                                    <Link to="/">Apps</Link>
                                    <Link to="/">Development</Link>
                                    <Link to="/">Tips</Link>
                                    <Link to="/">Responsive</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-xs-12">
                            <ul className="social-media">
                                <li>
                                    <a
                                        href="https://www.linkedin.com/company/lampnet-solutions/mycompany/?viewAsMember=true"
                                        className="ion-social-linkedin"
                                    >
                                        <span></span>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://web.facebook.com/LampnetS"
                                        className="ion-social-facebook"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <span></span>
                                    </a>
                                </li>

                                <li>
                                    <a
                                        href="https://www.instagram.com/lampnet_solutions/"
                                        className="ion-social-instagram"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <span></span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <div className="copy-right text-right">
                                © {new Date().getFullYear()} Lampnets. All
                                rights reserved
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <div className="footer-height"></div>
    </>
)

export default FooterTwo
