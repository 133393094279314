import React from 'react'
import Slider from "react-slick";
import dataModel from "../../data/Models/model-one-data.json";

import img2 from "../../assets/images/bg-right-image-4.jpg";


const Partners = () => {
  const settings = {
    className: "model-slider",
    dots: true,
    autoplay: true,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: "0",
  };
 

  return(
  <section className="section dark-bg">
  <div className="bg-wrap">
    <div className="col-md-6 col-sm-4 bg-flex bg-flex-right">
      <div
        className="bg-flex-holder bg-flex-cover"
        style={{ backgroundImage: `url(${img2})` }}
      ></div>
    </div>
  </div>
  <div className="container">
    <div className="col-md-5 col-sm-7">
      <h1 className="mb-0 play-font white-color">Our Partners</h1>
      <h3 className="mt-10 kaushan-font pink-color">
        Passion for People
      </h3>
      <Slider {...settings}>
        {dataModel.map((item) => (
          <div className="row item-model" key={item.id}>
            <div className="col-md-8">
              <img
                src={require("../../assets/images/" + item.image)}
                alt={item.title}
              />
              {/* <div className="model-box title-section">
                <h4 data-backletter={"0" + item.id}>
                  <a href={process.env.PUBLIC_URL}>
                    {item.title}
                  </a>
                </h4>
                <p>{item.role}</p>
              </div> */}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  </div>
  </section>
  )
}

export default Partners


