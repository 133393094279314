import React from "react";
import ReactWow from "react-wow";
import { Link } from 'react-router-dom'


const WhoWeAreTwo = ({ data }) => {



return (
    <>
        <section className="p-0">
            <Link to="/">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-8 section-heading pb-0">
                            <h2 className="mt-5">Our Categories</h2>
                            <hr className="dark-bg" />
                        </div>
                    </div>
                    <div className="row row-flex">
                        {data.map((service) => (
                            <div className="col-md-4" key={service.id}>
                                <div
                                    className={"col-inner spacer " + service.bg}
                                >
                                    <ReactWow animation="fadeInUp" delay="0.1s">
                                        <div className="text-center white-color">
                                            <i
                                                className={
                                                    service.icon +
                                                    " font-40px white-icon"
                                                }
                                            ></i>
                                            <h5 className="text-uppercase mt-40">
                                                {service.title}
                                            </h5>
                                            <p>{service.text}</p>
                                        </div>
                                    </ReactWow>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Link>
        </section>
    </>
)
}

export default WhoWeAreTwo;
