import React from "react";

const Marker = ({ icon, text }) => (
  <div>
    <img src={icon} alt="" />
    <p className="text-danger" style={{fontSize: "18px"}}>{text}</p>
  </div>
);

export default Marker;
