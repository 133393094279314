import React from 'react'

import PartnerSlider from '../../components/Slider/PartnerSlider'
//import WhoWeAreSeven from '../../components/WhoWeAre/WhoWeAreSeven';
import FooterTwo from "../../components/Footer/FooterTwo"
import TestimonialTwo from "../../components/Testimonial/TestimonialTwo"

// DATA Files
import dataNavbar from "../../data/Navbar/nav-fitness-data.json";
import dataProgram from '../../data/Program/program-one.data.json'

import dataTestimonial from "../../data/Testimonials/testimonials-one-data.json"
import NavbarSimpleSocial from "../../components/Navbar/NavbarSimpleSocial"
import Partner from "../../components/Partner/Partners"
import MapSection from "../../components/Map/MapSection"
import ContactSix from "../../components/Contact/ContactSix"
import Program from '../../components/Program/Program';
const PartnerShipProgram = () => {
    return (
        <div>
            <NavbarSimpleSocial data={dataNavbar} type="white" />
            <PartnerSlider />
            <Program data={dataProgram} title="Our Partners Programmes" />
            {/* <WhoWeAreSeven
                data={dataServices}
                title="LAMPNET ALLIANCE PROGRAM (LAP)"
                text="Join hands with the fastest growing globally relevant IT Solutions Company and grab the opportunity to market and deliver tested and trusted world-class on-demand mobile app solutions which promises various life-time income opportunities."
            /> */}
            <Partner />
            
            <TestimonialTwo data={dataTestimonial} />
            <MapSection />
            <ContactSix title="Contact Us" />
            <FooterTwo />
        </div>
    )
}

export default PartnerShipProgram
