import React from "react"
import ReactWow from "react-wow"
//import ProgressBarOne from "../ProgressBar/ProgressBarOne"

const WhoWeAreFive = ({ dataSkill, dataFeature }) => (
    <section style={{ backgroundColor: "#ffce6a" }}>
        <div className="container">
            <div className="row">
                <div className="col-md-12 section-heading">
                    <h2 className="text-uppercase  text-center font-700 mt-0 ">
                        WHY PARTNER <br />{" "}
                        <span className="font-400">WITH US</span>
                    </h2>
                </div>
            </div>

            <div className="row">
                <div className="col-md-4">
                    {dataFeature.slice(0, 3).map((feature, i) => (
                        <ReactWow
                            animation="fadeInUp"
                            delay={`0.${i + 1}s`}
                            key={feature.id}
                        >
                            <div className="feature-box text-left mb-30">
                                <div className="pull-left">
                                    <i
                                        className={`${feature.icon} font-40px dark-icon`}
                                    ></i>
                                </div>
                                <div className="pull-right">
                                    <h4 className="mt-0 font-600">
                                        {feature.title}
                                    </h4>
                                    <p className="font-400">{feature.text}</p>
                                </div>
                            </div>
                        </ReactWow>
                    ))}
                </div>

                <div className="col-md-4">
                    {dataFeature.slice(3, 5).map((feature, i) => (
                        <ReactWow
                            animation="fadeInUp"
                            delay={`0.${i + 1}s`}
                            key={feature.id}
                        >
                            <div className="feature-box text-left mb-30">
                                <div className="pull-left">
                                    <i
                                        className={`${feature.icon} font-40px dark-icon`}
                                    ></i>
                                </div>
                                <div className="pull-right">
                                    <h4 className="mt-0 font-600">
                                        {feature.title}
                                    </h4>
                                    <p className="font-400">{feature.text}</p>
                                </div>
                            </div>
                        </ReactWow>
                    ))}
                </div>

                <div className="col-md-4">
                    {dataFeature.slice(5, 7).map((feature, i) => (
                        <ReactWow
                            animation="fadeInUp"
                            delay={`0.${i + 1}s`}
                            key={feature.id}
                        >
                            <div className="feature-box text-left mb-30">
                                <div className="pull-left">
                                    <i
                                        className={`${feature.icon} font-40px dark-icon`}
                                    ></i>
                                </div>
                                <div className="pull-right">
                                    <h4 className="mt-0 font-600">
                                        {feature.title}
                                    </h4>
                                    <p className="font-400">{feature.text}</p>
                                </div>
                            </div>
                        </ReactWow>
                    ))}
                </div>

                {/* <ProgressBarOne data={dataSkill} /> */}
            </div>
        </div>
    </section>
)

export default WhoWeAreFive
