export const nameValidator = (name) => {
    if (!name.trim()) return "Please enter your name"

    if (name.trim() !== "") {
        if (!name.match(/^[a-zA-Z" "\s]+$/)) return "Invalid format"
    }
    if (name.length < 5) return "a minumum of five character is required"
}
export const emailValidator = (email) => {
    if (!email.trim()) return "Please enter your email address"

    if (email !== "") {
        if (!/\S+@\S+\.\S+/.test(email))
            return "Email address format is invalid"
    }
}

export const passwordValidator = (password) => {
    if (!password.trim()) return "Please enter your password"

    if (password !== "") {
        // if (!/^([1-zA-Z0-1@.\s]{1,255})$/.test(password)) return  "Invalid  character"
        if (password.length < 5)
            return "a minimum of five characters is required"
    }
}
export const phoneValidator = (phone) => {
    if (!phone.trim()) return "Please enter your phone number"

    if (isNaN(phone) || phone.toString().length !== 11)
        return "Invalid phone format"
}

export const confirmPassword = (pass1, pass2) => {
    if (pass1 !== "" && pass2 !== "" && pass1 !== pass2)
        return "password mismatch"
}

export const stringValidator = (value) => {
    if (!value.trim()) return "This field  cannot be blank"
}

export const subjectValidator = (value) => {
    if (!value.trim()) return "Please Enter Your Subject"
}

export const messageValidator = (value) => {
    if (!value.trim()) return "Please Enter Your Message"
    if(value.length < 20 ) return "Please your Message is too short"
}
