import React, { useState } from "react"
import * as validator from "./../../helpers/validator"
import { contactFun } from "./contactFun"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const ContactSix = ({ tagline }) => {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState("")
    const [isProcessing, setIsProcessing] = useState("")
    const [formMsg, setFormMsg] = useState("")
    const [showMsg, setShowMsg] = useState(false)

    const [nameErr, setNameErr] = useState("")
    const [emailErr, setEmailErr] = useState("")
    const [subjectErr, setSubjectErr] = useState("")
    const [messageErr, setMessageErr] = useState("")

    const handleChangeName = (e) => {
        setName(e.target.value)
        setNameErr("")
    }

    const handleChangeEmail = (e) => {
        setEmail(e.target.value)
        setEmailErr("")
    }

    const handleChangeSubject = (e) => {
        setSubject(e.target.value)
        setSubjectErr("")
    }

    const handleChangeMessage = (e) => {
        setMessage(e.target.value)
        setMessageErr("")
    }

    const notify = msg => toast.success(msg)
    const resetForm = () => {
     
        setName("")
        setEmail("")
        setMessage("")
        setSubject("")
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        let error = false
        if (validator.nameValidator(name)) {
            error = true
            setNameErr(validator.nameValidator(name))
        }
        if (validator.emailValidator(email)) {
            error = true
            setEmailErr(validator.emailValidator(email))
        }
        if (validator.subjectValidator(subject)) {
            error = true
            setSubjectErr(validator.subjectValidator(subject))
        }

        if (validator.messageValidator(message)) {
            error = true
            setMessageErr(validator.messageValidator(message))
        }

        if (!error) {
            let formData = {
                name,
                email,
                subject,
                message
            }
            
            contactFun(URL, formData, setIsProcessing, setFormMsg, setShowMsg, notify, resetForm)
        }
    }
    return (
      <>
      <ToastContainer/>
        <section id="contact" className="white-bg form-style-one">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 text-center col-md-offset-2">
                        <h2 className="text-uppercase font-700 mt-0">
                            Contact <span className="font-400">Us</span>
                        </h2>
                        <hr className="dark-bg"></hr>
                        {/* <h2 className="mt-10 text-uppercase font-700 letter-spacing-10">
            Book a Table
          </h2> */}
                    </div>
                </div>

                <div className="row mt-50">
                    <div className="col-md-8 col-md-offset-2">
                        <form
                            name="contact-form"
                            id="contact-form"
                            onSubmit={handleSubmit}
                            noValidate
                        >
                            <div className="messages"></div>
                            <div className="row">
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <label
                                            className="sr-only"
                                            htmlFor="name"
                                        >
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            name="name"
                                            className="form-control"
                                            id="name"
                                            placeholder="Your Name"
                                            onChange={handleChangeName}
                                            value={name}
                                        />
                                        <span className="text-danger">
                                            {nameErr}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <label
                                            className="sr-only"
                                            htmlFor="email"
                                        >
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            name="email"
                                            className="form-control"
                                            id="email"
                                            placeholder="Your Email"
                                            onChange={handleChangeEmail}
                                            value={email}
                                        />
                                        <span className="text-danger">
                                            {emailErr}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label
                                            className="sr-only"
                                            htmlFor="subject"
                                        >
                                            Subject
                                        </label>
                                        <input
                                            type="text"
                                            name="subject"
                                            className="form-control"
                                            id="subject-2"
                                            placeholder="Your Subject"
                                            onChange={handleChangeSubject}
                                            value={subject}
                                        />
                                        <span className="text-danger">
                                            {subjectErr}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="sr-only" htmlFor="message">
                                    Message
                                </label>
                                <textarea
                                    name="message"
                                    className="form-control"
                                    id="message"
                                    rows="7"
                                    placeholder="Your Message"
                                    onChange={handleChangeMessage}
                                    value={message}
                                ></textarea>
                                <span className="text-danger">
                                    {messageErr}
                                </span>
                            </div>
                            <div className="text-left">
                                {isProcessing ? (
                                    <button
                                        type="submit"
                                        name="submit"
                                        className="btn btn-outline-color btn-md btn-circle btn-animate remove-margin"
                                        disabled
                                    >
                                        <span>
                                            Sending...{" "}
                                            <i className="ion-android-arrow-forward"></i>
                                        </span>
                                    </button>
                                ) : (
                                    <button
                                        type="submit"
                                        name="submit"
                                        className="btn btn-outline-color btn-md btn-circle btn-animate remove-margin"
                                    >
                                        <span>
                                            Send Message{" "}
                                            <i className="ion-android-arrow-forward"></i>
                                        </span>
                                    </button>
                                )}
                            </div>
                            <br />
                            {showMsg && (
                                <div
                                    className="alert alert-danger"
                                    role="alert"
                                >
                                    <p>{formMsg}</p>
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </>
    )
}

export default ContactSix
