import React from "react";
import { Link } from 'react-router-dom'
// DATA Files
import dataNavbar from "../../data/Navbar/nav-fitness-data.json";
import dataBlog from "../../data/Blog/blog-two-data.json";
import dataTestimonial from "../../data/Testimonials/testimonials-one-data.json"

// Images
import imgBg from "../../assets/images/slides/blog.jpg";

// Components
import NavbarSimpleSocial from "../../components/Navbar/NavbarSimpleSocial";
import PageTitleThree from "../../components/PageTitle/PageTitleThree";
import FooterTwo from "../../components/Footer/FooterTwo";
import TestimonialTwo from "../../components/Testimonial/TestimonialTwo";
import MapSection from "../../components/Map/MapSection";
import ContactSix from "../../components/Contact/ContactSix";
const BlogStandardPage = () => (
    <>
        <NavbarSimpleSocial data={dataNavbar} />
        <PageTitleThree
            title="Our Blog"
            tagline="Recent Articles"
            image={imgBg}
        />
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-md-9 col-sm-9">
                        {dataBlog.slice(0, 1).map((item) => (
                            <div className="post mb-50" key={item.id}>
                                <div className="post">
                                    <div className="post-img">
                                        <img
                                            className="img-responsive"
                                            src={require("../../assets/images/" +
                                                item.image)}
                                            alt=""
                                        />
                                    </div>
                                    <div className="post-info">
                                        <h3>
                                            <Link to={item.path}>
                                                {item.title}
                                            </Link>
                                        </h3>
                                        <h6>{item.published}</h6>
                                        <hr />
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="blog-standard">
                            <p className="font-300">
                                From surging demand to fractured supply chains,
                                last year tested the food industry like never
                                before. All the upheaval put digital
                                transformation in the food industry into
                                hyper-drive. Faced with challenges on all
                                fronts, food companies stepped up their digital
                                transformation efforts. They focused on
                                optimizing their processes for maximum
                                efficiency and building resiliency into their
                                supply chains. The goal wasn’t only to survive
                                the pandemic challenge, but to come out stronger
                                and ready for whatever challenge may come next.
                            </p>
                            <blockquote>
                                <h3>Technology in food services</h3>
                                <p>
                                    One of the ideal technologies in the food
                                    service industry tends to be ghost or cloud
                                    kitchens, inspiring from the effort of
                                    saving cost for a physical location..
                                    Accordingly, most restaurants have to open
                                    their virtual store in some food delivery
                                    apps for adopting with changing customers’
                                    desires.
                                </p>
                                <p>
                                    In Nigeria however, LampNet solutions has a
                                    suite of solutions for the food industry
                                    from food ordering to restaurant management,
                                    to food delivery, all possessing rich
                                    features from organized processes allocation
                                    to order management, reservations, stock
                                    management, amidst cutting edge innovative
                                    features.
                                </p>
                            </blockquote>
                        </div>
                        <div className="post-tags">
                            <Link to="/blog">Design</Link>
                            <Link to="/blog">Branding</Link>
                            <Link to="/blog">Stationery</Link>
                            <Link to="/blog">Development</Link>
                            <Link to="/blog">Concept</Link>
                        </div>

                        <h2 className="comment-reply-title mt-30">
                            0 Comments:
                        </h2>

                        <div id="respond" className="comment-respond">
                            <h2
                                id="reply-title"
                                className="comment-reply-title"
                            >
                                Leave a Comment
                            </h2>
                            <form id="form-comments" className="comment-form">
                                <div className="row-form row">
                                    <div className="col-form col-md-6">
                                        <div className="form-group">
                                            <input
                                                name="author"
                                                type="text"
                                                className="form-control"
                                                placeholder="Name"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-form col-md-6">
                                        <div className="form-group">
                                            <input
                                                name="email"
                                                type="text"
                                                className="form-control"
                                                placeholder="Email"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <textarea
                                        name="comment"
                                        id="comment-field"
                                        className="form-control"
                                        placeholder="Comment"
                                        rows="5"
                                    ></textarea>
                                </div>

                                <p className="form-submit">
                                    <input
                                        name="submit"
                                        type="submit"
                                        id="submit-btn"
                                        disabled
                                        className="btn btn-color btn-md btn-default remove-margin"
                                        value="Post Comment"
                                    />
                                    <input
                                        type="hidden"
                                        name="comment_post_ID"
                                    />
                                    <input
                                        type="hidden"
                                        name="comment_parent"
                                        id="comment_parent"
                                        value="0"
                                    />
                                </p>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-3">
                        <div className="widget sidebar_widget">
                            <form className="search-form">
                                <input
                                    type="text"
                                    name="name"
                                    className="form-control search-field"
                                    id="search"
                                    placeholder="Type what it's your mind..."
                                />
                                <button
                                    type="submit"
                                    disabled
                                    className="fa fa-search search-submit"
                                ></button>
                            </form>
                        </div>
                        <div className="widget widget_about">
                            {/* <h5 className="widget-title">About</h5>
                            <p className="font-300">
                                Lorem ipsum dolor sit amet consectetur
                                adipiscing elit. Sed dui lorem adipiscing in
                                adipiscing et.
                            </p> */}
                        </div>
                        <div className="widget sidebar_widget widget_archive">
                            <h5 className="widget-title">Archive</h5>
                            <ul>
                                <li>
                                    <Link  to ="/blog">
                                        January 2017
                                    </Link>
                                </li>
                                <li>
                                    <Link  to ="/blog">
                                        May 2016
                                    </Link>
                                </li>
                                <li>
                                    <Link  to ="/blog">
                                        June 2015
                                    </Link>
                                </li>
                                <li>
                                    <Link  to ="/blog">
                                        Febuary 2017
                                    </Link>
                                </li>
                                <li>
                                    <Link  to ="/blog">
                                        April 2015
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="widget sidebar_widget widget_categories">
                            <h5 className="widget-title">Categories</h5>
                            <ul>
                                <li>
                                    <Link  to ="/blog">
                                        Business
                                    </Link>
                                </li>
                                <li>
                                    <Link  to ="/blog">Health</Link>
                                </li>
                                <li>
                                    <Link  to ="/blog">
                                        Motion Graphic
                                    </Link>
                                </li>
                                <li>
                                    <Link  to ="/blog">
                                        Conecpt Design
                                    </Link>
                                </li>
                                <li>
                                    <Link  to ="/blog">
                                        Lifestyle
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="widget sidebar_widget widget_tag_cloud">
                            <h5 className="widget-title">Tags</h5>
                            <div className="post-tags">
                                <Link  to ="/blog">Design</Link>
                                <Link  to ="/blog">Envato</Link>
                                <Link  to ="/blog">Photography</Link>
                                <Link  to ="/blog">Videos</Link>
                                <Link  to ="/blog">Creative</Link>
                                <Link  to ="/blog">Apps</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <TestimonialTwo data={dataTestimonial} />
        <MapSection />
        <ContactSix title="Contact Us" />
        <FooterTwo />
    </>
)

export default BlogStandardPage;
