import { applicationApi } from "../../api/api"

export const application = async (
    URL, formData, setIsProcessing, setMessage, setShowErr, notify, resetForm
) => {
    try {
        const response = await applicationApi(
            "/application",
            formData,
            setIsProcessing,
            setMessage,
            setShowErr,
            notify,
            resetForm
        )

        setIsProcessing(false)
        notify(response.data.message)
        resetForm()
     
    } catch (error) {
        if (error.response) {
            setMessage(error.response.data.message)
            setShowErr(true)
        }
    }
}
