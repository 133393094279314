import React from "react";
// DATA Files
import dataNavbar from "../../data/Navbar/nav-fitness-data.json";
import dataServices from "../../data/Services/services-ten-data.json";
import dataFeatures from "../../data/Services/services-one-data.json";
// Images
import imgBg from "../../assets/images/slides/title-bg-30.jpg";
import imgParallax from "../../assets/images/background/project-img.jpg";
// Components
import NavbarSimpleSocial from "../../components/Navbar/NavbarSimpleSocial";
import PageTitleTwo from "../../components/PageTitle/PageTitleTwo";
import ServiceFive from "../../components/Service/ServiceFive";
import WhoWeAreTen from "../../components/WhoWeAre/WhoWeAreTen";
import ParallaxThree from "../../components/Parallax/ParallaxThree";      
import FooterTwo from "../../components/Footer/FooterTwo";
import TestimonialTwo from "../../components/Testimonial/TestimonialTwo";
import dataTestimonial from "../../data/Testimonials/testimonials-one-data.json";
import MapSection from "../../components/Map/MapSection";
import ContactSix from "../../components/Contact/ContactSix";
const ServicesPage = () => (
 <>
    <NavbarSimpleSocial data={dataNavbar} type="white" />
    
    <PageTitleTwo title="Our Services" tagline="" image={imgBg} />
    <ServiceFive data={dataServices} />
    {/* <PricingThree data={dataPricing} /> */}
    <WhoWeAreTen data={dataFeatures} />
    <ParallaxThree
      image={imgParallax}
      title="Strategic Digital Solutions"
      text=""
    />

<TestimonialTwo data={dataTestimonial} />
    <MapSection />
    <ContactSix title="Contact Us" /> 
    <FooterTwo />
  </>
);

export default ServicesPage;
