import React from "react";

const ContactInfoOne = () => (
  <section className="p-0 white-bg">
    <div className="container-fluid">
      <div className="row row-flex">
        <div className="col-md-4">
          <div className="col-inner spacer light-br-r">
            <div className="text-center">
              <i className="icon-map-pin font-50px indigo-icon"></i>
              <h3>Office Address</h3>
              <p>
                128 Itire Rd, Lawanson Surulere Lagos, Nigeria. <br />
               
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="col-inner spacer light-br-r">
            <div className="text-center">
              <i className="icon-envelope font-50px indigo-icon"></i>
              <h3>Email Us</h3>
              <p className="mb-0">info@lampnets.com.</p>
              <p>info@lampnets.com.</p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="col-inner spacer">
            <div className="text-center">
              <i className="icon-dial font-50px indigo-icon"></i>
              <h3>Call Us</h3>
              <p className="mb-0">+234 816 663 7303</p>
              <p> +234 706 953 6407</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default ContactInfoOne;
