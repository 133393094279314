import React from "react"
import ReactWow from "react-wow"

const Program = ({ data, title, pattern }) => (
    <section className={pattern ? "pattern-bg-dark" : ""}>
        <div className="container">
            <div className="row">
                <div className="col-sm-8 section-heading">
                    <h2>{title}</h2>
                    <hr className="dark-bg" />
                </div>
            </div>
            <div className="row mt-50">
                {data.map((post, i) => (
                    <div className="col-md-6" key={post.id}>
                        <ReactWow animation="fadeInUp" delay={`0.${i}s`}>
                            <div className="post">
                                <div className="post-img">
                                    <img
                                        className="img-responsive"
                                        src={require("../../assets/images/" +
                                            post.image)}
                                        alt={post.title}
                                    />
                                </div>
                                <div className="post-info">
                                    {/* <h2> {post.title} </h2> */}
                                    <h4
                                        className="mt-0 font-600"
                                        style={{ color: "#6f4444" }}
                                    >
                                        {post.title}
                                    </h4>
                                    <h3 style={{ color: "#212121" }}>
                                        {post.published}
                                    </h3>
                                    <hr />
                                    <div className="mt-10">
                                        <ul style={{ color: "#6f4444" }}>
                                            <li>{post.first}</li>
                                            <li>{post.second}</li>
                                            <li>{post.third}</li>
                                            <li>{post.four}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </ReactWow>
                    </div>
                ))}
            </div>
        </div>
    </section>
)

export default Program
