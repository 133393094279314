import React from "react";
import ReactWow from "react-wow";
const ServiceOne = ({ data, title, tagline }) => (
    <section className="grey-bg">
        <div className="container">
            <div className="row">
                <div className="col-sm-12 text-center">
                    <h1 className="mb-0 mt-0 font-700 kaushan-font text-uppercase">
                        {title}
                    </h1>
                    <h2 className="mt-10 font-200 letter-spacing-10 source-font text-uppercase">
                        {tagline}
                    </h2>
                </div>
            </div>
            <div className="row mt-50">
                {data.map((service, i) => (
                    <ReactWow
                        animation="fadeInUp"
                        delay={`0.${i}s`}
                        key={service.id}
                    >
                        <div className="col-md-3 col-sm-6">
                            <div className="service-box">
                                <div className="service-img">
                                    <img
                                        className="img-responsive"
                                        src={require("../../assets/images/" +
                                            service.image)}
                                        alt={service.title}
                                    />
                                </div>
                                <div className="service-box-info">
                                    <h3>{service.title}</h3>
                                    <p>{service.text}</p>
                                </div>
                            </div>
                        </div>
                    </ReactWow>
                ))}
            </div>
        </div>
    </section>
)

export default ServiceOne;
