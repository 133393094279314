import React from "react";
// DATA Files
import dataNavbar from "../../data/Navbar/nav-fitness-data.json";
import dataClients from "../../data/Client/client-one-data.json";
import TestimonialTwo from "../../components/Testimonial/TestimonialTwo";
import dataTestimonial from "../../data/Testimonials/testimonials-one-data.json";
import dataPortfolio from "../../data/Portfolio/portfolio-six-data.json";
// Images
import imgBg from "../../assets/images/slides/title-bg-28.jpg";

// Components
import NavbarSimpleSocial from "../../components/Navbar/NavbarSimpleSocial";
import FooterTwo from "../../components/Footer/FooterTwo";
import MapSection from "../../components/Map/MapSection";
import ContactSix from "../../components/Contact/ContactSix";
import ClientOne from "../../components/Client/ClientOne";
import PageTitleTwo from "../../components/PageTitle/PageTitleTwo";
import PortfolioMasonryThreeSpace from "../../components/Portfolio/PortfolioMasonryThreeSpace";


const ProductPage = () => {
  
  return(
 <>
    <NavbarSimpleSocial data={dataNavbar} />
    <PageTitleTwo
      title="Our Products"
      tagline=""
      image={imgBg}
    />
    <PortfolioMasonryThreeSpace data={dataPortfolio} />
    <ClientOne data={dataClients} classes="pt-100 pb-100" />
    <TestimonialTwo data={dataTestimonial} />
    <MapSection />
    <ContactSix title="Contact Us" /> 
    <FooterTwo />
</>
    )
}

export default ProductPage;

