import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";
import ScrollIntoView from "./helpers/ScrollIntoView";
// Home pages
import HomeAppLanding from "./pages/home/HomeAppLanding";
import AboutUsPage from "./pages/inner/AboutUsPage";
import ProductPage from "./pages/inner/ProductPage";
import Page404 from "./pages/inner/Page404";
import CareersPage from "./pages/inner/CareersPage"
import ServicesPage from './pages/inner/ServicesPage'
import ContactPage from "./pages/inner/ContactPage";
import ProductSinglePage from './pages/portfolio/PortfolioSingleOne'
import PartnerShipProgram from "./pages/inner/PartnerShipProgram";
import ApplicationForm from "./pages/inner/ApplicationForm";
import BlogStandardPage from './pages/blog/BlogStandardPage'
// Blog Pages



const App = () => {
  return (
      <Router basename={"/"}>
          <ScrollIntoView>
              <ScrollToTop>
                  <Switch>
                      <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/`}
                          component={HomeAppLanding}
                      />

                      <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/about-us`}
                          component={AboutUsPage}
                      />

                      <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/career`}
                          component={CareersPage}
                      />
                      <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/our-products`}
                          component={ProductPage}
                      />

                      <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/our-services`}
                          component={ServicesPage}
                      />
                      <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/contact-us`}
                          component={ContactPage}
                      />
                      <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/single-product`}
                          component={ProductSinglePage}
                      />
                      <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/partnership`}
                          component={PartnerShipProgram}
                      />
                      <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/application-form`}
                          component={ApplicationForm}
                      />
                      <Route
                        
                          exact
                          path={`${process.env.PUBLIC_URL}/blog`}
                          component={BlogStandardPage}
                      />
                      <Route component={Page404} />
                  </Switch>
              </ScrollToTop>
          </ScrollIntoView>
      </Router>
  )
}

export default App;
