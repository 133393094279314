import React from "react";
import ReactWow from "react-wow";
//import img from "../../assets/images/phone.png";
import { Link } from 'react-router-dom'
const AppSlider = () => (
    <section className="home-slider white-bg" id="home">
        <div className="full-screen-bg">
            <div className="hero-text-wrap parallax-bg fixed-bg title-hero-bg pattern-bg-light">
                <div className="hero-text white-color fade-center-text">
                    <div className="container">
                        <div className="row">
                            <ReactWow animation="fadeInUp" delay="0.1s">
                                <div className="col-md-6 pt-100 pb-20 col-sm-6 white-color">
                                    <h2 className="font-500 source-font" style={{fontSize: "50px"}}>
                                        No. 1  Mobile Application Development Company  
                                        <br />
                                        In West Africa.
                                    </h2>
                                    <h5 className="white-color mt-10 source-font line-height-26">
                                        LampNet provides SAAS, PAAS, IAAS
                                        services for African businesses of all
                                        sizes
                                    </h5>
                                    <p className="text-left mt-30">
                                        <Link
                                            to="about-us"
                                            className="btn btn-outline btn-lg btn-animate text-white"
                                        >
                                            <span>
                                                Learn More
                                                <i className="eicon ion-coffee"></i>
                                            </span>
                                        </Link>
                                    </p>
                                </div>
                            </ReactWow>
                            {/* <ReactWow animation="fadeInRight" delay="0.3s">
                <div className="col-md-4 i-phones col-md-offset-2 col-sm-4 col-sm-offset-2 mt-80">
                  <img className="img-responsive" src={img} alt="iphone-1" />
                </div>
              </ReactWow> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default AppSlider;
