import React from "react";
// DATA Files
import dataNavbar from "../../data/Navbar/nav-fitness-data.json";
import dataClients from "../../data/Client/client-one-data.json"

// Images
import imgBg from "../../assets/images/slides/title-bg-24.jpg";
import imgOpenings from "../../assets/images/career-img-1.jpg";
// Components
import NavbarSimpleSocial from "../../components/Navbar/NavbarSimpleSocial";
import PageTitleTwo from "../../components/PageTitle/PageTitleTwo";
import WhoWeAreEleven from "../../components/WhoWeAre/WhoWeAreEleven";
import OpeningOne from "../../components/Opening/OpeningOne";
import FooterTwo from "../../components/Footer/FooterTwo";
import MapSection from "../../components/Map/MapSection";
import ContactSix from "../../components/Contact/ContactSix";
import ClientOne from "../../components/Client/ClientOne"



const CareersPage = () => (
    <>
        <NavbarSimpleSocial data={dataNavbar} type="white" />

        <PageTitleTwo
            title="Careers"
            tagline="Currently jobs opening"
            image={imgBg}
        />
        <WhoWeAreEleven title="Together at Lampnet" text="Work With Us" />

        {/* <PerksOne data={dataPerks} title="Looking for Perks?" /> */}
        <OpeningOne title="Current Openings" image={imgOpenings} />
        <ClientOne data={dataClients} classes="pt-100 pb-100" />
        <MapSection />
        <ContactSix title="Contact Us" />
        <FooterTwo />
    </>
)

export default CareersPage;
