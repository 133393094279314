import React from "react";
import { Link } from 'react-router-dom'


const OpeningOne = ({ title, image }) => {



  return (
      <>
          <section className="pt-0 pb-0">
              <div className="container-fluid">
                  <div className="row row-flex flex-center">
                      <div className="col-md-6 col-sm-12">
                          <div className="col-inner pt-70 pb-50 spacer">
                              <div className="title-section">
                                  <h2 className="mt-10 font-700">{title}</h2>
                                  <hr className="dark-bg left-line" />
                              </div>
                              <ul className="careers-listing">
                                  <li>
                                      <div className="row">
                                          <div className="career-main col-md-9">
                                              <h5>UI/UX Designer</h5>
                                              {/* <span className="career-type">
                                                  Part time
                                              </span>
                                              <span className="career-location">
                                                  <i className="mdi mdi-map-marker-circle"></i>
                                                  Nigeria
                                              </span> */}
                                          </div>
                                          <div className="career-details col-md-3">
                                              <Link
                                                  to="/application-form"
                                                  className="btn btn-outline-color btn-sm btn-default btn-animate remove-margin"
                                              >
                                                  Apply Now
                                              </Link>
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="row">
                                          <div className="career-main col-md-9">
                                              <h5>Presales Engineer</h5>
                                              {/* <span className="career-type">
                                                  Full time
                                              </span> */}
                                              {/* <span className="career-location">
                                                  <i className="mdi mdi-map-marker-circle"></i>
                                                  Los Angeles, USA
                                              </span> */}
                                          </div>
                                          <div className="career-details col-md-3">
                                              <Link
                                                  to="/application-form"
                                                  className="btn btn-outline-color btn-sm btn-default btn-animate remove-margin"
                                              >
                                                  Apply Now
                                              </Link>
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="row">
                                          <div className="career-main col-md-9">
                                              <h5>Sales Intern</h5>
                                              {/* <span className="career-type">
                                                  Full time
                                              </span>
                                              <span className="career-location">
                                                  <i className="mdi mdi-map-marker-circle"></i>
                                                  Dubai, United Arab Emirates
                                              </span> */}
                                          </div>
                                          <div className="career-details col-md-3">
                                              <Link
                                                  to="/application-form"
                                                  className="btn btn-outline-color btn-sm btn-default btn-animate remove-margin"
                                              >
                                                  Apply Now
                                              </Link>
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                     
                                  </li>
                              </ul>
                          </div>
                      </div>
                      <div
                          className="col-md-6 col-sm-12 bg-flex-cover"
                          style={{ backgroundImage: `url(${image})` }}
                      ></div>
                  </div>
              </div>
          </section>
      </>
  )
}


export default OpeningOne;
