import React from "react"
import ReactWow from "react-wow"
import img from "../../assets/images/team.jpg"

const PartnerSlider = () => (
    <section className="home-slider" id="home">
        <div className="full-screen-bg">
            <div className="hero-text-wrap light-overlay-bg1 pattern-bg-light1">
                <div className="hero-text white-color fade-center-text">
                    <div className="container">
                        <div className="row">
                            <ReactWow animation="fadeInUp" delay="0.3s">
                                <div className="col-md-6 pt-120 pb-20 col-sm-6 white-color">
                                    <h2 className="font-200 source-font">
                                        Lampnet Alliance
                                        <br />
                                        Programme (LAP)
                                    </h2>
                                    <h5 className="white-color mt-30 source-font line-height-26">
                                        Join hands with the fastest growing
                                        globally relevant IT Solutions Company
                                        and grab the opportunity to market and
                                        deliver tested and trusted world-class
                                        on-demand mobile app solutions which
                                        promises various life-time income
                                        opportunities.
                                    </h5>
                                </div>
                            </ReactWow>
                            <ReactWow animation="fadeInRight" delay="0.5s">
                                <div className="col-md-4 i-phones col-md-offset-2 col-sm-4 col-sm-offset-2 mt-80">
                                    <img
                                        className="img-responsive img-fluid img-rounded"
                                        src={img}
                                        alt="iphone-1"
                                    />
                                </div>
                            </ReactWow>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default PartnerSlider
