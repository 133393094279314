import React from "react";
// DATA Files
import dataNavbar from "../../data/Navbar/nav-fitness-data.json";
//import dataFeatures from "../../data/Feature/feature-two-data.json";
import dataClasses from "../../data/Classes/classes-one-data.json";
import ClassesOne from "../../components/Classes/ClassesOne";
import ServiceOne from "../../components/Service/ServiceOne"
//import dataTeam from "../../data/Team/team-one-data.json";
import dataBlog from "../../data/Blog/blog-one-data.json";
import dataServices from "../../data/Services/services-two-data.json"
import TestimonialTwo from "../../components/Testimonial/TestimonialTwo";
import dataTestimonial from "../../data/Testimonials/testimonials-one-data.json";
import dataServicess from "../../data/Services/services-five-data.json"
import dataResume from "../../data/Resume/resume-one-data.json"
import ResumeOne from "../../components/Resume/ResumeOne"
// Images

// Components
import NavbarSimpleSocial from "../../components/Navbar/NavbarSimpleSocial";
import AppSlider from "../../components/Slider/AppSlider";
import FooterTwo from "../../components/Footer/FooterTwo";
// import FeatureOne from "../../components/Feature/FeatureOne";
import Partner from "../../components/Partner/Partners"
import MapSection from "../../components/Map/MapSection";
import ContactSix from "../../components/Contact/ContactSix";
import BlogOne from "../../components/Blog/BlogOne";
import WhoWeAreTwo from "../../components/WhoWeAre/WhoWeAreTwo";
//import TeamFour from "../../components/Team/TeamFour";
//import DownloadOne from "../../components/Download/DownloadOne";
import imgWelcome from "../../assets/images/bg-right-image.jpg";
import WelcomeOne from "../../components/Welcome/WelcomeOne";



const HomeAppLanding = () => {
  const [toggler, setToggler] = React.useState(false);
 
  return (
      <>
          <NavbarSimpleSocial data={dataNavbar} />
          <AppSlider />

          <WelcomeOne
              image={imgWelcome}
              setToggler={setToggler}
              toggler={toggler}
          />
         
          <ResumeOne data={dataResume} title="My Resume" backfont="Resume" />

          {/* <MenuOne data={dataMenu} /> */}
          <ServiceOne data={dataServicess} title="What We do" tagline="" />
          <ClassesOne data={dataClasses} title="Our Products" />
          <WhoWeAreTwo data={dataServices} />

          <BlogOne data={dataBlog} title="Our Blogs" />

          <Partner />

          {/* <ClientOne data={dataClients} classes="pt-100 pb-100" /> */}
          <TestimonialTwo data={dataTestimonial} />
          <MapSection />
          <ContactSix title="Contact Us" />
          <FooterTwo />
      </>
  )
}

export default HomeAppLanding;

