import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
// DATA Files
import dataNavbar from "../../data/Navbar/nav-fitness-data.json";


//import dataTeam from "../../data/Team/team-one-data.json";
import dataCounter from "../../data/Counter/counter-one-data.json";

// Images
import imgBg from "../../assets/images/slides/title-bg-27.jpg";


// Components
import NavbarSimpleSocial from "../../components/Navbar/NavbarSimpleSocial";
import PageTitleTwo from "../../components/PageTitle/PageTitleTwo";
import dataServices from "../../data/Services/services-three-data.json";

// import TeamSix from "../../components/Team/TeamSix";
import CounterOne from "../../components/Counter/CounterOne";
import FooterTwo from "../../components/Footer/FooterTwo";
import AboutMeSix from "../../components/About/AboutMeSix";
import WhoWeAreThree from "../../components/WhoWeAre/WhoWeAreThree";

import MapSection from "../../components/Map/MapSection";
import ContactSix from "../../components/Contact/ContactSix";
import dataSkills from "../../data/Skill/skill-two-data.json"
import dataFeatures from "../../data/Feature/feature-one-data.json"
import WhoWeAreFive from "../../components/WhoWeAre/WhoWeAreFive"
const AboutUsPage = () => {
  const [toggler] = useState(false);

  return (
      <>
          <FsLightbox
              toggler={toggler}
              sources={["https://www.youtube.com/watch?v=sU3FkzUKHXU"]}
          />
          <NavbarSimpleSocial data={dataNavbar} type="white" />
          <PageTitleTwo
              title="About Us"
              tagline="Digital Creative Agency"
              image={imgBg}
          />

          <AboutMeSix />

          <WhoWeAreThree
              data={dataServices}
              title="About Lampnet"
              tagline="Brand, design and digital expertise"
          ></WhoWeAreThree>
          {/* <ResumeOne data={dataResume} title="My Resume" backfont="Resume" /> */}
          <WhoWeAreFive dataSkill={dataSkills} dataFeature={dataFeatures} />

          {/* <TeamSix data={dataTeam} title="Creative Team" /> */}
          <CounterOne data={dataCounter} />
          <MapSection />
          <ContactSix title="Contact Us" />
          <FooterTwo />
      </>
  )
 
};

export default AboutUsPage;
