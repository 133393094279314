import React from "react"

const RequirementOne = () => {
    return (
        <>
            <section style={{paddingBottom: "15px"}}>
                <div className="row">
                    <div className="container">
                        <div class="col-sm-8 section-heading">
                            <h2>Application</h2>
                            <hr class="dark-bg" />
                        </div>

                        <div className="col-md-12">
                            <h4 className="text-center">UI/UX Designer</h4>
                            <p>
                                We are currently hiring a passionate, user
                                centered UI/UX Designer to join a collaborative
                                and innovative team to create visually
                                delightful and easy-to-use digital products in a
                                fast-paced environment.
                            </p>
                            <h4 className="">
                                Main Responsibilities of a UI/UX Designer
                            </h4>
                            <p>Your responsibilities will include:</p>
                            <ul>
                                <li>
                                    Creating user-centered designs by
                                    understanding business requirements, and
                                    user feedback
                                </li>
                                <li>
                                    Creating user flows, wireframes, prototypes
                                    and mockups
                                </li>
                                <li>
                                    Translating requirements into style guides,
                                    design systems, design patterns and
                                    attractive user interfaces
                                </li>
                                <li>
                                    Designing UI elements such as input
                                    controls, navigational components and
                                    informational components
                                </li>
                                <li>
                                    Creating original graphic designs (e.g.
                                    images, sketches and tables)
                                </li>

                                <li>
                                    Identifying and troubleshooting UX problems
                                    (e.g. responsiveness)
                                </li>

                                <li>
                                    Collaborating effectively with product,
                                    engineering, and management teams
                                </li>
                            </ul>
                            <h4 className="">Key Requirements</h4>
                            <ul>
                                <li>
                                    You have a degree/diploma in Design, Fine
                                    Arts, Engineering or related field
                                </li>
                                <li>
                                    You have prior experience as a UI/UX
                                    Designer as well as a strong portfolio of
                                    related projects
                                </li>
                                <li>
                                    You are proficient in Adobe Creative Suite
                                    (specifically Illustrator, InDesign and
                                    Photoshop)
                                </li>
                                <li>
                                    You are proficient in prototyping tools such
                                    as Sketch, In Vision, etc.
                                </li>
                                <li>
                                    Basic HTML5, CSS3, and JavaScript skills are
                                    a plus
                                </li>
                                <li>
                                    You pay strong attention to detail and have
                                    a keen eye for aesthetics
                                </li>
                                You have excellent communication skills and can
                                clearly articulate your ideas, designs, and
                                suggestions
                                <li>
                                    You are a strong team player who can
                                    collaborate effectively with different
                                    stakeholders
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="container">
                        <div className="col-md-12">
                            <h4 className="text-center">Presales Engineer</h4>
                            <p>
                                The Pre-Sales Engineer (PSE) supports sales
                                productivity and deal flow by securing the
                                “technical close” in complex solutions. The PSE
                                collaborates with sales, service, engineering,
                                and technical support resources to ensure
                                proposed deals include technical solutions that
                                accurately address customer needs, and are
                                appropriately supported by key customer
                                technical decision-makers. The PSE is
                                responsible for achieving a profit and
                                productivity quota made up of the combined
                                expectations of the sales resources, market,
                                and/or channel supported. Reporting to the Sales
                                Engineering Director, the PSE reports on a
                                dotted line to the sales manager or business
                                line manager responsible for the sales team
                                supported.
                            </p>
                            <h4>
                                Main Responsibilities of a Presales Engineer
                            </h4>
                            <ul>
                                <li>
                                    Works deals assigned to the sales team
                                    supported, prioritizing effort based on
                                    maximizing total impact on team productivity
                                    and profit, or as directed by the sales
                                    manager.
                                </li>
                                <li>
                                    Proactively scopes the technical solution
                                    required to address customer requirements,
                                    assesses customers’ met and unmet needs, and
                                    recommends solutions that optimize value for
                                    both the customer and the firm.
                                </li>
                                <li>
                                    Secures input from all necessary solution
                                    stakeholders within the customer firm.
                                    Adapts solutions, as necessary, to ensure
                                    appropriate support.
                                </li>

                                <li>
                                    Coordinates closely with internal sales,
                                    sales support, and service resources to
                                    align solution design with customers’
                                    business requirements.
                                </li>

                                <li>
                                    Secures from customer technical staff
                                    commitments needed to ensure a deal’s
                                    “technical close.”
                                </li>

                                <li>
                                    Meets assigned targets for profitable sales
                                    growth in assigned product lines, market
                                    areas, channel, or teams supported.
                                </li>

                                <li>
                                    Provides coaching and professional
                                    development to team member sales associates
                                    in order to enhance their product knowledge,
                                    technical acumen, and technical sales
                                    skills.
                                </li>
                                <li>
                                    Opportunistically pursues additional
                                    business development opportunities within
                                    customer firms. Collaborates with sales to
                                    ensure these opportunities a re-effectively
                                    covered and advanced
                                </li>
                                <li>
                                    Monitors customer support for technical
                                    solutions proposed throughout the sales
                                    process, and alerts the sales and account
                                    teams to potential risks of deal closure.
                                </li>
                            </ul>
                            <h4 className="">Key Requirements</h4>

                            <ul>
                                <li>
                                    Four year college degree from an accredited
                                    institution
                                </li>
                                <li>
                                    Minimum six years of sales or sales
                                    engineering experience in a
                                    business-to-business, large/strategic
                                    customer segment; or
                                </li>
                                <li>
                                    A record of achievement and technical
                                    solution expertise in a comparable sales
                                    role.
                                </li>
                                <li>
                                    Product certification, engineering
                                    credential, or equivalent technical
                                    credential.
                                </li>
                                <li>PC proficiency</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="container">
                        <div className="col-md-12">
                            <h4 className="text-center">Sales Intern</h4>
                            <p>
                                We are searching for a committed,
                                deadline-driven sales intern to join our
                                excellent sales team. The sales intern will
                                complete all tasks assigned by the manager,
                                which may include conducting market research,
                                identifying business opportunities, managing
                                email campaigns, generating sales leads, making
                                cold calls, participating in meetings, and
                                assisting the Sales department wherever
                                possible. You should be able to take
                                instruction, but also work unsupervised when
                                required.
                            </p>
                            <p>
                                To be successful as a sales intern, you should
                                be willing to learn as much about the company as
                                possible and be able to spot opportunities that
                                have been overlooked by others. Outstanding
                                sales interns are problem solvers who know the
                                value of building lasting, professional
                                relationships with clients.
                            </p>
                            <h4>Sales Intern Responsibilities</h4>
                            <ul>
                                <li>
                                    Working closely with the manager or assigned
                                    staff member, and completing all allocated
                                    tasks.
                                </li>
                                <li>
                                    Conducting desktop research, or gathering
                                    information through surveys or by speaking
                                    to clients and staff.
                                </li>
                                <li>
                                    Attending and participating in meetings,
                                    workshops, events, and exhibitions.
                                </li>

                                <li>
                                    Liaising with clients, vendors, and
                                    suppliers on behalf of the company's
                                    managers.
                                </li>

                                <li> Updating documents and sales records.</li>

                                <li>Meeting sales target and</li>

                                <li>
                                    Observing and carrying out sales processes.
                                </li>
                                <li>
                                    Identifying potential weaknesses and
                                    offering improvement suggestions.
                                </li>
                                <li>
                                    Overseeing and developing marketing
                                    campaigns
                                </li>
                                <li>
                                    Conducting research and analyzing data to
                                    identify and define audiences
                                </li>

                                <li>
                                    Devising and presenting ideas and strategies
                                </li>

                                <li>Promotional activities</li>
                                <li>
                                    Compiling and distributing financial and
                                    statistical information
                                </li>

                                <li>Writing and proofreading creative copy</li>

                                <li>
                                    Maintaining websites and looking at data
                                    analytics
                                </li>

                                <li>
                                    Organizing events and product exhibitions
                                </li>

                                <li>
                                    Updating databases and using a customer
                                    relationship management (CRM) system
                                </li>
                                <li>
                                    Coordinating internal marketing and an
                                    organization’s culture
                                </li>

                                <li>Monitoring performance</li>

                                <li> Managing campaigns on social media.</li>
                                <li>
                                    Keeping a log of everything learned and
                                    delivering presentations to staff and other
                                    stakeholders.
                                </li>
                            </ul>
                            <h4 className="">Key Requirements</h4>

                            <ul>
                                <li>
                                    College degree from an accredited institute.
                                </li>
                                <li>
                                    Degree in marketing, business or similar may
                                    be required.
                                </li>
                                <li> Strong self-motivation.</li>
                                <li>
                                    Ability to work alone or as part of a team.
                                </li>
                                <li>
                                    Proficiency in a foreign language would be
                                    advantageous.
                                </li>

                                <li> Ability to cope with rejection.</li>
                                <li>
                                    Ability to remain calm in fast-paced
                                    environments.
                                </li>

                                <li> Superb interpersonal skills.</li>

                                <li> A professional appearance.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RequirementOne
